import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Tooltip, Tabs, Tab, Box, Typography } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  ChartTooltip,
  Legend
);

const SidebarLeftStats = ({ station_data, closeTraffic }) => {
  const [tabValue, setTabValue] = useState(0);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const hours = Array.from({ length: 24 }, (_, i) => i);

  const { sortedData, maxVolume } = useMemo(() => {
    const sorted = [...station_data].sort((a, b) => a.dayOfWeek - b.dayOfWeek);
    const max = Math.max(...station_data.flatMap(day => day.volumePerHour));
    return { sortedData: sorted, maxVolume: max };
  }, [station_data]);

  const getOpacity = useCallback((volume) => {
    return (volume / maxVolume).toFixed(2);
  }, [maxVolume]);

  const [avgTrafficDaily, setAvgTrafficDaily] = useState();
  const [avgTrafficHourly, setAvgTrafficHourly] = useState();
  const numberWithCommas = (x) =>  {
    if(!x) return ''
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    const totalVolume = station_data.reduce((sum, day) => sum + day.volumePerHour.reduce((daySum, hourVolume) => daySum + hourVolume, 0), 0);
    const average = numberWithCommas(Math.round(totalVolume / 7));
    setAvgTrafficDaily(average);
    const totalHours = station_data.length * 24;
    const average_hourly = numberWithCommas(Math.round(totalVolume / totalHours));
    
    setAvgTrafficHourly(average_hourly);
  }, [station_data]);

  const formatHour = (hour) => {
    return hour === 0 ? '12 AM' : hour < 12 ? `${hour} AM` : hour === 12 ? '12 PM' : `${hour - 12} PM`;
  };

  const hourLabels = useMemo(() => {
    return hours.map(hour => (
      <div key={hour} style={{ 
        fontSize: '8px', 
        textAlign: 'center', 
        writingMode: 'vertical-rl', 
        transform: 'rotate(180deg)',
        height: '40px',
        color: '#a7a7a7',
        fontWeight: 'bold'
      }}>
        {formatHour(hour)}
      </div>
    ));
  }, []);

  const dailyData = {
    labels: days,
    datasets: [{
      label: 'Daily Traffic',
      data: sortedData.map(day => day.volumePerHour.reduce((sum, volume) => sum + volume, 0)),
      backgroundColor: 'rgba(0,38,62,0.5)',
      borderColor: 'rgba(0,38,62,1)',
      borderWidth: 1
    }]
  };

  const hourlyData = {
    labels: hours.map(formatHour),
    datasets: [{
      label: 'Hourly Traffic',
      data: hours.map(hour => sortedData.reduce((sum, day) => sum + day.volumePerHour[hour], 0) / 7),
      fill: false,
      borderColor: 'rgba(0,38,62,0.5)',
      tension: 0.1
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,

      },
      title: {
        display: false,
        text: 'Traffic Data',
      },
    },
  };

  return (
    <div className={station_data.length > 0 ? "sidebar-stats-total" : "sidebar-stats-total sidebar-stats-total-hideval"} style={{ backgroundColor: '#eff6fe' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <h3 className='absolute pt-5 pl-20'>
                Traffic Data
            </h3>
            <button 
                onClick={closeTraffic}
                className='close-button'
                style={{ background: 'none', border: 'none', fontSize: '30px', cursor: 'pointer' }}
            >
                ×
            </button>
        </div>

      <div className="relative">
        <div className='traffic-stats-number'>
          <div className="font-main-text">Average Daily Traffic</div>
          <div className="font-main-text mt-10 text-color-blue">{avgTrafficDaily}</div>            
        </div>        

        <div className='traffic-stats-number'>
          <div className="font-main-text">Average Hourly Traffic</div>
          <div className="font-main-text mt-10 text-color-blue">{avgTrafficHourly}</div>            
        </div>        
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '20px'}}>
          <Tabs 
        value={tabValue} 
        onChange={(event, newValue) => setTabValue(newValue)} 
        aria-label="traffic data tabs"
        centered
        >
            <Tab label="Weekly" />
            <Tab label="Daily" />
            <Tab label="Hourly" />
        </Tabs>
      </Box>

      <Box sx={{ p: 1, backgroundColor: '#ffffff' }}>
        {tabValue === 0 && (
          <div className='relative'>
            <div className="grid-container" style={{
              display: 'grid',
              gridTemplateColumns: 'auto repeat(24, 12px)',
              gridGap: '1.5px',
              margin: '20px 20px 25px 5px'
            }}>
              <div style={{ width: '40px', height: '12px' }}></div>
              {hourLabels}
              {sortedData.map((dayData) => (
                <React.Fragment key={dayData.dayOfWeek}>
                  <div style={{ 
                    fontSize: '10px', 
                    width: '40px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'flex-end', 
                    paddingRight: '4px',
                    color: '#a7a7a7',
                    fontWeight: 'bold'
                  }}>
                    {days[dayData.dayOfWeek].slice(0, 3)}
                  </div>
                  {dayData.volumePerHour.map((volume, hour) => (
                    <Tooltip
                      key={`${dayData.dayOfWeek}-${hour}`}
                      title={`${days[dayData.dayOfWeek]}, ${formatHour(hour)}: ${volume}`}
                      arrow
                    >
                      <div 
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#FDDA0D',
                          opacity: getOpacity(volume),
                        }}
                      ></div>
                    </Tooltip>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        {tabValue === 1 && (
          <div style={{ height: '180px' }}>
            <Bar data={dailyData} options={chartOptions} />
          </div>
        )}
        {tabValue === 2 && (
          <div style={{ height: '180px' }}>
            <Line data={hourlyData} options={chartOptions} />
          </div>
        )}
 
      </Box>
    </div>
  );
};

export default React.memo(SidebarLeftStats);