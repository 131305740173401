import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import OutsideClickHandler from 'react-outside-click-handler';

const OtherLayerSidebar = ({ otherLayerClosed, otherLayerShow, otherLayersChange, otherLayersList }) => {
  
    return (
    <OutsideClickHandler onOutsideClick={otherLayerClosed}>
      <div className={otherLayerShow ? "Building-total top-325 top-auto" : "Building-total Building-total-hideval top-325"}>
        <div className="Building-title pl-15">Other Layers</div>
        <div className="Building-total-each pl-15 height-auto">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => otherLayersChange('Zipcodes')}
                />
              }
              label={
                <Typography
                sx={{ fontSize: '16px', fontWeight: 600 }}>
                  Zipcode
                </Typography>
              }
              sx={{ mb: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => otherLayersChange('Cities')}
                />
              }
              label={
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  City
                </Typography>
              }
              sx={{ mb: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => otherLayersChange('Urban_area')}
                />
              }
              label={
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  Urban Areas
                </Typography>
              }
              sx={{ mb: 1 }}
            />
          </FormGroup>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default OtherLayerSidebar;