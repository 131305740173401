import React, {Component} from 'react';
import axios from 'axios';
import maptize from '../Images/maptize_logo_white-01.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select';
import industry_avgs from '../Competitor/industry_avgs.json'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'; // Import Box
import {PopupRegisterSelection} from './registrationPopUpSelection';
const occupation = industry_avgs.map((av) => {return {value: av.type, label: av.type.replace("_", " ")}}).sort((a, b) => a.value.localeCompare(b.value));



export class PopUpLogin extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            search_competitor: "",
            searched: [],
            name: "",
            handlepass: "",
            handleregemail: "",
            handlemail:"",
            handlepassword: ""

        }

    }


    handlenameChange = (e) =>{
        this.setState({classes_name: 'has-val', name: e.target.value})
    }

    handlemail = (e) => this.setState({handlemail: e.target.value})
    handlepassword = (e) => this.setState({handlepassword: e.target.value})
    login = async () => {
        if(this.state.handlepassword.length < 1) return this.setState({error_value:"Please Enter a password", passwordvalid: false})
        if(this.state.handlemail.length < 1) return this.setState({error_value:"Please Enter a Email", passwordvalid: false})

        try{
        let response = await axios.post('/signin/auth', { password: this.state.handlepassword, email: this.state.handlemail, type : 'verify'    })
        let res = await response.data;
        console.log(res);
        localStorage.setItem("auth-token", response.headers['auth-token'])
        window.location.reload(false);


        }catch(e){
            this.setState({error_value: e.response.data })
        }
    }
    
    render() {
        return (
        <Dialog open={true} sx={{
            '& .MuiDialog-paper': {
                margin: 0, 
            },
        }}>
                <DialogTitle>Login</DialogTitle>
                <div  sx={{ justifyContent: 'center' }}>
                <div>  
                    <div className="margin-x-10">                  
                    <div className="">
                    <input  className="popup-register-name"   onChange={this.handlemail} type="text" name="name" value={this.state.handlemail} placeholder="Email" />
                    <input className="popup-register-name" type="password" onChange={this.handlepassword}  name="name" value={this.state.handlepassword} placeholder="Password" />
                    <div>
                    <span className="txt1">
								Forgot
							</span>
							<a href="/passwordreset" className="txt2">
								 Email / Password?
							</a>
                    </div>
                    {(this.state.error_value != null)? <div className= "Competitor-register-error">{this.state.error_value}</div>:null}
                    </div>
                    </div>

                    </div>
                </div>
                <DialogActions>
                <Button className='color-orange' onClick={() => this.props.changeRegisterType(false)}>Sign Up</Button>
                <Button className='color-dark-blue' onClick={this.login}>Login</Button>
                </DialogActions>
        </Dialog>
    )}
}
