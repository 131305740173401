import React, { Component } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';


export class PopupRegister extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_competitor: "",
            searched: [],
            name: "",
            handlepass: "",
            handleregemail: "",
            handlemail: "",
            handlepassword: "",
            termsAccepted: false,
            passwordErrors: [],
            token: null
        }
        this.categoryselector = this.categoryselector.bind(this);
        this.valuesetter = this.valuesetter.bind(this);
        this.occupation_setter = this.occupation_setter.bind(this);
    }

    // Existing methods remain the same
    valuesetter(values) {
        this.setState({ eachcat: values })
    }

    occupation_setter(value) {
        this.setState({ occupation: value })
    }

    async categoryselector() {
        var token = localStorage.getItem('auth-token');
        var cat_f = [];
        var cat_all = await axios.post('/setup/cattotal');
        let cat_all_final = await cat_all.data;
        this.setState({ resp: cat_all_final })
    }

    componentWillMount() {
        this.categoryselector()
        const params = new URLSearchParams(window.location.search);
        const name = params.get('name');
        const email = params.get('email');

        if (name && email) {
            this.setState({
                name: decodeURIComponent(name),
                handleregemail: decodeURIComponent(email)
            });
        }
    }

    // Modified methods for enhanced validation
    validatePassword = (password) => {
        const errors = [];
        
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }
        if (!password.match(/[A-Z]/)) {
            errors.push("Password must contain at least one uppercase letter");
        }
        if (!password.match(/[a-z]/)) {
            errors.push("Password must contain at least one lowercase letter");
        }
        if (!password.match(/[0-9]/)) {
            errors.push("Password must contain at least one number");
        }
        if (!password.match(/[^A-Za-z0-9]/)) {
            errors.push("Password must contain at least one special character");
        }

        return errors;
    }

    handlepass = (e) => {
        const password = e.target.value;
        const passwordErrors = this.validatePassword(password);
        this.setState({ 
            handlepass: password,
            passwordErrors: passwordErrors
        });
    }

    handleTermsChange = (e) => {
        this.setState({ termsAccepted: e.target.checked });
    }

    isFormValid = () => {
        console.log()
        return (
            this.state.token && 
            this.state.name.trim().length > 0 && 
            this.state.handlepass.length >= 8 && 
            this.state.passwordErrors.length === 0 && 
            this.state.termsAccepted
        );
    }

    register = async () => {
        if (!this.state.token) {
            return this.setState({ error_value: "Please complete the Captcha properly" });
        }
        if (this.state.name.trim().length < 1) {
            return this.setState({ error_value: "Please provide your full name" });
        }
        if (this.state.passwordErrors.length > 0) {
            return this.setState({ error_value: "Please fix all password requirements", passwordvalid: false });
        }
        if (!this.state.termsAccepted) {
            return this.setState({ error_value: "Please accept the terms and conditions" });
        }

        try {
            let response = await axios.post('/signup/freetier', {
                token: this.state.token,
                name: this.state.name.trim(),
                password: this.state.handlepass,
                email: this.state.handleregemail,
                mapRegister: true
            });
            let res = await response.data;
            localStorage.setItem("auth-token", response.headers['auth-token']);
            this.props.changeUnregistered(false);
        } catch (e) {
            this.setState({ error_value: e.response.data });
        }
    }
    handlenameChange = (e) =>{
        this.setState({classes_name: 'has-val', name: e.target.value})
    }

    handlemail = (e) => this.setState({handlemail: e.target.value})
    handleregemail = (e) => this.setState({handleregemail: e.target.value})
    captcha =(e) => this.setState({token: e})


    render() {
        const passwordFieldStyle = {
            position: 'relative',
            marginBottom: this.state.passwordErrors.length > 0 ? '8px' : '25px'
        };

        const passwordRequirementsStyle = {
            marginTop: '4px',
            marginBottom: '16px'
        };

        return (
            <Dialog open={true} sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                },
            }}>
                <DialogTitle>Sign Up</DialogTitle>
                <DialogContent>
                    <div className="margin-x-10">
                        <input 
                            className="popup-register-name width-100" 
                            onChange={this.handlenameChange} 
                            type="text" 
                            name="name" 
                            value={this.state.name} 
                            placeholder="Full Name" 
                        />
                        <input 
                            className="popup-register-name width-100" 
                            onChange={this.handleregemail} 
                            type="text" 
                            name="name" 
                            value={this.state.handleregemail} 
                            placeholder="Business Email" 
                        />
                        <div style={passwordFieldStyle}>
                            <input 
                                className="popup-register-name width-100" 
                                type="password" 
                                onChange={this.handlepass} 
                                name="name" 
                                value={this.state.handlepass} 
                                placeholder="Password" 
                                style={{
                                    marginBottom: 10,
                                    borderBottomColor: this.state.passwordErrors.length > 0 ? '#d32f2f' : 'black'
                                }}
                            />
                            
                            {this.state.passwordErrors.length > 0 && (
                                <Stack spacing={0.5} style={passwordRequirementsStyle}>
                                    {this.state.passwordErrors.map((error, index) => (
                                        <Typography 
                                            key={index} 
                                            variant="caption" 
                                            style={{
                                                color: '#d32f2f',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '11px',
                                                lineHeight: '1.2',
                                                marginLeft: '4px'
                                            }}
                                        >
                                            • {error}
                                        </Typography>
                                    ))}
                                </Stack>
                            )}
                        </div>

                        <ReCAPTCHA 
                            sitekey="6LdY69UZAAAAAMVP_K9AVLSOOvk6Y6wNl75Q7lO-" 
                            onChange={this.captcha}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={this.state.termsAccepted}
                                    onChange={this.handleTermsChange}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    I agree to the{' '}
                                    <Link href="https://mapchise.com/terms-and-conditions/" target="_blank">
                                        Terms
                                    </Link>
                                    {' '}and{' '}
                                    <Link href="https://mapchise.com/privacy" target="_blank">
                                        Privacy Policy
                                    </Link>
                                </Typography>
                            }
                        />

                        {this.state.error_value && (
                            <Typography color="error" className="Competitor-register-error">
                                {this.state.error_value}
                            </Typography>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        className='color-orange' 
                        onClick={() => this.props.changeRegisterType(true)}
                    >
                        Login
                    </Button>
                    <Button 
                        className='color-dark-blue' 
                        onClick={this.register}
                        disabled={!this.isFormValid()}
                    >
                        Sign Up
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}