import React, {Component, useState, useEffect} from 'react';
import 'leaflet/dist/leaflet.css';
import {Psychographic} from './Psychographic';
import axios from 'axios';
import {Statusside} from './statusside.js';
import DemographicInfo from './DemographicInfo';
import {SidebarMainLocations} from './ui_components/sidebarMainLocations'
import {MapMainFindings} from './ui_components/MapMainFindings'
import {SidebarMainHousing} from './ui_components/sidebarMainHousing'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import {Maptaskmanagement} from './map_task_manager';
// import { saveAs } from 'file-saver';
import {Loader_sidebar} from './loader_sidebar'
import {ExportDetails} from './ui_components/ExportDetails.js'
import {Commercial_estate} from './commercial_realestate.js'
import DemographicInternational from './ui_components/DemographicInternational'
import PsychographicInternational from './ui_components/PsychographicInternational'

export const Sidebar_left = (props) => {
  const [state, setState] = useState({
    search: [],
    squareclicked: 'none',
    showresult: 'none',
    industryclicked: false,
    filter: null,
    fss: true,
    cluster: true,
    question: 'none',
    taskmanagement: true,
    showManagementBool: true,
    percent_diff: null,
    color_percentdiff: null,
    arrangement: [],
    hidesidebar: false,
    zoom: 4
  });

  const [exportInProgress, setExportInProgress] = useState(false);
    
   

  // Equivalent to componentDidMount and componentDidUpdate for taskmanager prop
  useEffect(() => {
    if (props.taskmanager) {
      setState(prevState => ({ ...prevState, taskmanagement: props.taskmanager, showManagementBool: props.taskmanager }));
      props.resettaskmanager();
    }
  }, [props.taskmanager]);

  useEffect(() => {
    if(props.mapLocationClicked) {
      showManagementMapping(props.mapLocationClicked, true)
    }else{
      setState(prevState => ({...prevState,idvalue: {}, showManagementBool: true}))
    }
  }, [props.mapLocationClicked])

  // Equivalent to componentDidMount
  useEffect(() => {
    let sidebarstate = localStorage.getItem("statesidebar");
    if (sidebarstate != null && sidebarstate !== 'null')
      setState(prevState => ({ ...prevState, filter: sidebarstate }));

    getstartvalues();
  }, []);

    
    const industry_showmore = () =>  {
      if(props.family)
      setState(prevState => ({ ...prevState, industryclicked: false, zoom: 1, taskmanagement:true}));
    }
    const industry_back = () => {
      setState(prevState => ({ ...prevState, industryclicked: false}));
    }
    const industry_clicked = () => {
      setState(prevState => ({ ...prevState, industryclicked: true}))
    }
    const industry_hidemore = () => {
      if(props.degree)
      setState(prevState => ({ ...prevState, industryclicked: false, zoom: 0, taskmanagement:true}));
    }
    const dem_showmore = () => {
      
      setState(prevState => ({ ...prevState, dem_showmore: true, zoom: 2, taskmanagement: true}));
    }
    const housing_tab = () => {
      if(props.housing_buy)
      setState(prevState => ({ ...prevState, zoom: 3, taskmanagement:true}))
    }

    const closeFindingLocation = () => {
      if(exportInProgress) return alert('Export In Progress');
      setState(prevState => ({...prevState, taskmanagement:true, showManagementBool: true}))
    }

    const filtersetter = (e) => {
      localStorage.setItem("statesidebar", e)
      setState(prevState => ({ ...prevState, filter: e}))
    }

    
    const showManagementMapping = async (e, mapClicked) => {
      if(!mapClicked) props.getcirclerange(e.name)
      setState(prevState => ({ ...prevState, showManagementBool:false, idvalue: {...e}}))
    }
    
    const setarrangement = async (num) => {
      let token = localStorage.getItem("auth-token");
      console.log(num, 'num')
      let arr = state.arrangement;
      arr.push(num)
      setState(prevState => ({ ...prevState, arrangement: arr}));
      axios.post('/updatearrangement', {arrangement: arr}, {headers:{ "auth-token": token}});
      
    }
    const unsetarrangement = async (num) => {
      let token = localStorage.getItem("auth-token");
      console.log(num, 'num', state.arrangement)
      let arr = state.arrangement;
      let r = arr.filter((e) => {
        if(num != e) return e})
      setState(prevState => ({ ...prevState, arrangement: r}));
      axios.post('/updatearrangement', {arrangement: r}, {headers:{ "auth-token": token}});
    }
    const getstartvalues = async () => {
      let token = localStorage.getItem("auth-token");
      let arrangement_vals = null;
      try{
      let arr = await axios.post('/arrangement', {}, {headers:{ "auth-token": token}});
      arrangement_vals = await arr.data.arrangement;
      }catch(e){
        if(!e.response){
          return window.location.href = "/login";
        }
        if(e.response.status === 400) 
        {
          localStorage.removeItem("auth-token")
          window.location.href = "/login";
         }
      }
      if(arrangement_vals == null)
      // window.location.href = "/setup";
      console.log(arrangement_vals, 'arr values')
      setState(prevState => ({ ...prevState, arrangement: arrangement_vals}))
    }

    const showlocations = () => {
      if(state.zoom !== 4)
      setState(prevState => ({ ...prevState, zoom: 4}))
    }

    const exportData = async () => {
      if(exportInProgress) return;
      setExportInProgress(true)
      props.parentExportInProgress(true)
      setTimeout(async () => {
        const input = document.getElementById('pdf-export-container');
        if (input) {
          const canvas = await html2canvas(input, { scale: 2 });
          const imgData = canvas.toDataURL('image/png');
          const pdfWidth = 595.28; // A4 width in pixels at 72 DPI
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Calculate height maintaining the aspect ratio
      
          const pdf = new jsPDF({
            orientation: pdfHeight > pdfWidth ? 'portrait' : 'landscape', 
            unit: 'pt', 
            format: [pdfWidth, pdfHeight]
          });
      
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(`mapchise_${state.idvalue.name}.pdf`);
        }
        setExportInProgress(false)
        props.parentExportInProgress(false)
      }, 2000)
    
    }
    
    const Psychograph_change = (age, income) =>{ console.log(age, income, "inside psychograph"); setState(prevState => ({ ...prevState, Psychographic_filters:{age, income}}))}
    // Psychograph_updated = () =>{ setState(prevState => ({ ...prevState, Psychograph_updated: false})}

      
      return (
        <div id="sidebar-top">
      {/* {(state.hidesidebar)?<div className="sidebar-closed" onClick={() => setState(prevState => ({ ...prevState, hidesidebar: true})}><div className="fa fa-angle-left"></div></div>:null} */}
        <div>
      <div className={(!state.hidesidebar)?"sidebar-total":"sidebar-total sidebar-total-hide"}>
        <div className={!state.hidesidebar?"sidebar-open": "sidebar-close"} onClick={() => {props.hidesidebar(); setState(prevState => ({ ...prevState, hidesidebar: !state.hidesidebar}))}}><div className={!state.hidesidebar? "fa fa-angle-right": "fa fa-angle-left" }></div>{/*<div className="sidebar-open-desc">Collapse sidebar</div>*/}</div>
      


      <div id="sidebar-scroll">
          { exportInProgress &&
            <div>
            <div id="pdf-export-container">
            <div id="pdf-export-container-details" className='pt-20'>
              <ExportDetails showManagementBool={state.showManagementBool} idvalue={state.idvalue}/>
            </div>
              {props.municipality_data ? <DemographicInternational municipality_data={props.municipality_data}/> : <DemographicInfo exportInd state={state} props={props} industry_hidemore={industry_hidemore} dem_showmore={dem_showmore} unsetarrangement={unsetarrangement} setarrangement={setarrangement}/>}
              {props.municipality_data ? <PsychographicInternational municipality_data={props.municipality_data} /> : <Psychographic exportInd circle_range_clicked={props.circle_range_clicked} Psychographic_parent={props.Psychographic_parent} loading={props.type} age_income_overall={props.age_income_overall} Psychograph={props.psychograph} Psychograph_change={Psychograph_change} Psychographic_filters={state.Psychographic_filters} />}
              <Commercial_estate exportInd commercial_data={props.commercial_data} industrygroup={props.industrygroup}/>
            <div id="pdf-export-container-housing" className='pt-20'>
              <SidebarMainHousing exportInd housing_seasonality={props.housing_seasonality} housing_buy={props.housing_buy} housing_rent={props.housing_rent} type_industry={props.type_industry}/>
            </div>
            </div>
          </div>
          }

          {/* if the show more for a selection is clicked */}
        <div id="sidebar-total-desc" data-tut="reactour__data"><div className={(state.zoom == 4)? "topbar-total-each-selected topbar-total-each":"topbar-total-each"} onClick={showlocations}>Business Locations</div>
        <div className={(state.zoom == 0 || state.zoom == 2)? "topbar-total-each-selected topbar-total-each":"topbar-total-each"} onClick={industry_hidemore}>Demographic Information</div>
        <div className={(state.zoom == 6)? "topbar-total-each-selected topbar-total-each":"topbar-total-each"} onClick={() => {if(props.freetier) props.premiumFeatureClicked(); if(props.family && !props.freetier) setState(prevState => ({ ...prevState, zoom: 6}))}}>Psychographic Data {props.freetier && <div className="fa fa-lock Layer-filter-lock-freetier-sidebar" ></div>}</div>
        <div className={(state.zoom == 1)? "topbar-total-each-selected topbar-total-each":"topbar-total-each"} onClick={() => {(props.freetier) ? props.premiumFeatureClicked() : industry_showmore()}}>Commercial Real Estate {props.freetier && <div className="fa fa-lock Layer-filter-lock-freetier-sidebar1" ></div>}</div>
        <div className={(state.zoom == 3)? "topbar-total-each-selected topbar-total-each":"topbar-total-each"} onClick={housing_tab}>Housing Real Estate</div></div>
        
          {(props.circle_range_clicked)? <div className="sidebar-realestate-total"> <div className="current-locations-sidebar-box2"><div className="current-locations-sidebar">Location Filter</div><div className="name-locations-sidebar add-location-warning2">{props.circle_range_clicked}</div>{/*<div className="fa fa-times circle-remove-location"></div>*/}</div></div>:null}
          {(state.zoom == 6) && ( props.municipality_data ? <PsychographicInternational municipality_data={props.municipality_data} /> : <Psychographic circle_range_clicked={props.circle_range_clicked} Psychographic_parent={props.Psychographic_parent} loading={props.type} age_income_overall={props.age_income_overall} Psychograph={props.psychograph} Psychograph_change={Psychograph_change} Psychographic_filters={state.Psychographic_filters} />)}
            
          {(state.zoom == 4) ?  state.showManagementBool ? <SidebarMainLocations checkStatusExpanded={props.checkStatusExpanded} filterMapLocationsFunc={props.filterMapLocationsFunc} filterMapLocations={props.filterMapLocations} passesAllFilters={props.passesAllFilters} status={props.status} brand={props.brand} handleRemoveFilter={props.handleRemoveFilter} handleAddFilter={props.handleAddFilter} locationFilters={props.locationFilters} getlocations={props.getlocations} getprospectsidebar={props.getprospectsidebar} showManagementMapping={showManagementMapping}/>:<MapMainFindings exportInProgress={exportInProgress} exportData={exportData} defaultBrand={props.cat_all.brand} sidebarUpdateLocationArea={props.sidebarUpdateLocationArea} getlocationrange={props.getlocationrange} idvalue={state.idvalue} closeFindingLocation={closeFindingLocation} defaultRange={props.defaultRange} Competitor_type={props.Competitor_type} statusList={props.status} defaultTags={props.tags}/>:null}
          {/* : <div><div className="box-price box-showmore" onClick={() => setState(prevState => ({ ...prevState, taskmanagement:true}))}><div id="box-goback">Back</div></div> <Maptaskmanagement idvalue={state.idvalue} all_locations={props.all_locations}/></div>} */}

          {(state.zoom ==0 || state.zoom ==2) && ( props.municipality_data ? <DemographicInternational municipality_data={props.municipality_data}/> : <DemographicInfo state={state} props={props} industry_hidemore={industry_hidemore} dem_showmore={dem_showmore} unsetarrangement={unsetarrangement} setarrangement={setarrangement}/>)}     
          {((state.zoom==1))&&
            <div>
                <Commercial_estate commercial_data={props.commercial_data} industrygroup={props.industrygroup}/>
            </div>}
          {(state.zoom == 3) && <SidebarMainHousing housing_seasonality={props.housing_seasonality} housing_buy={props.housing_buy} housing_rent={props.housing_rent} type_industry={props.type_industry}/>} 
            </div>
        </div>

          </div>
          
        <Statusside searchBarGetBrand={props.searchBarGetBrand} competitorSearch={props.competitorSearch} placeSearch={props.placeSearch}  premiumFeatureClicked={props.premiumFeatureClicked} building_model={props.building_model} freetier={props.freetier} building={props.building} Competitor_type={props.Competitor_type.map((e) => e.label)} datalayer_show={props.datalayer_show} places_show={props.places_show} turf_area={props.turf_area} fss_hide={props.fss_hide} competitor_show={props.competitor_show} fss={props.fss} fss_main={props.fss_main} fss_main_show={props.fss_main_show} fss_show= {props.fss_show} places={props.places} refreshing_auto_value={props.refreshing_auto_value} refreshing_auto={props.refreshing_auto} showTourAgain={props.showTourAgain} showTour={props.showTour} prospect_prospect={props.prospect_prospect} createmarkerhere={props.createmarkerhere} maptilelayer={props.maptilelayer} shoppingcenter={props.shoppingcenter} traffic={props.traffic} droppingcheck={props.droppingcheck} center={props.center} dropthepin={props.dropthepin} cluster={props.cluster} datalayer={props.datalayer} otherLayersList={props.otherLayersList} otherLayerShow={props.otherLayerShow} turnthison={props.turnthison}/>
        </div>
        
      )
    
  }


