import React, { useState, useEffect } from 'react';
import { ReactComponent as Bakery } from './mapbox-svg/bakery-15.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import categories from './json/category_list.json';
import PlacesSwitch from './ui_components/PlacesSwitch'
const Places = ({
  places_category,
  places_subCategory,
  places_show,
  places_hide,
  places,
  places_switch,
  Places_zoom,
  places_change,
  places_change_deselect,
  places_change_closed,
  competitorCategory
}) => {
  useEffect(() => {
    Object.entries(competitorCategory).map(([key, value]) => {
      categories[key] = categories[key].filter((subcat) => !value.includes(subcat))
    })
    const competitorCategory_flat = Object.values(competitorCategory).flat();
    const categoriesAggTemp = {Competitor: competitorCategory_flat, ...categories}
    setCategoriesAgg(categoriesAggTemp)
    setCompetitorCategoryFlat(competitorCategory_flat)
  }, [competitorCategory])

  const [expanded, setExpanded] = useState({});
  
  const [categoriesAgg, setCategoriesAgg] = useState({});
  const [competitorCategoryFlat, setCompetitorCategoryFlat] = useState([]);

  const handleParentChange = (category) => (event) => {
    const newParentChecked = { ...places_category };
    const newChildChecked = {...places_subCategory};
    const checkedBool = event.target.checked;
    newParentChecked[category] = checkedBool;
    categoriesAgg[category].map((e) => newChildChecked[e] = checkedBool)
    places_change(newParentChecked, newChildChecked)

  };

  const handleChildChange = (category, subCategory) => (event) => {
    const newParentChecked = { ...places_category };
    const newChildChecked = {...places_subCategory};

    newChildChecked[subCategory] = event.target.checked;
    const validateCategory = categoriesAgg[category].find((e) => newChildChecked[e])
    if(!validateCategory) newParentChecked[category] = false

    places_change(newParentChecked, newChildChecked)
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };
  return (
    <OutsideClickHandler onOutsideClick={places_hide}>
      <div className={places_show ? "Building-total top-95" : "Building-total Building-total-hideval top-95"}>
        <div className="Building-title pl-15">Places</div>
        <label className="layer-toggle-switch">
          <input type="checkbox" checked={places} onChange={places_switch} />
          <span className="layer-toggle-slider layer-toggle-round"></span>
        </label>
        {Places_zoom ? <div className="Building-result-none">Zoom in to refresh</div> : null}
        <div className="Building-total-each">
          <FormGroup>
            {Object.entries(categoriesAgg).map(([category, subCategories]) => (
              <Accordion 
                key={category} 
                expanded={expanded[category] || false}
                onChange={handleAccordionChange(category)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: `${category === 'Competitor'? 'Orange' : 'default'}`}}/>}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={places_category[category] || false}
                        onChange={handleParentChange(category)}
                        onClick={(event) => event.stopPropagation()}
                      />
                    }
                    label={
                      <Typography 
                        sx={{ fontSize: '16px', fontWeight: 600, color: `${category === 'Competitor'? 'Orange' : 'default'}` }}
                      >
                        {category}
                      </Typography>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {subCategories.map(subCategory => (
                      <FormControlLabel
                        key={`${category}-${subCategory}`}
                        control={
                          <Checkbox
                            checked={places_subCategory[subCategory] || false}
                            onChange={handleChildChange(category, subCategory)}
                          />
                        }
                        label={
                          <Typography 
                            sx={{ fontSize: '14px', fontWeight: 500,  color: `${category === 'Competitor'? 'Orange' : 'default'}`  }}
                          >
                            {subCategory}
                          </Typography>
                        }
                        sx={{ mb: 1 }}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </FormGroup>        
          </div>
        <div className="Building-close">
          <div className="Building-deselect" onClick={places_change_deselect}>Reset</div>
          <PlacesSwitch places_change_closed={places_change_closed}/>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Places;
