import axios from 'axios';
import React, {Component} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import boundary_unselect from './Images/boundary_unselect.png';
import map_view from './Images/map_view.png'
import settings_map from './Images/settings.png';
import Tasks from './Images/TasksImage.png';
import tour_map from './Images/tour.png';
import building_model from './Images/3d_model.png';
import CompetitorImage from './Images/CompetitorImage.png'
import TutorialImage from './Images/TutorialImage.png'
import pin_img from './Images/pin.png';
import {ReactComponent as Places} from './Layer_images/Places.svg'
import {ReactComponent as Buildings} from './Layer_images/Buildings.svg'
import {ReactComponent as Cluster} from './Layer_images/Cluster.svg'
import {ReactComponent as Competitor} from './Layer_images/Competitor.svg'
import {ReactComponent as Datalayer} from './Layer_images/Datalayer.svg'
import {ReactComponent as Shop} from './Layer_images/Shop.svg'
import {ReactComponent as Zipcode} from './Layer_images/Zipcode.svg'
import {ReactComponent as Traffic} from './Layer_images/Traffic.svg'
import {ReactComponent as StreetView} from './Layer_images/StreetView.svg'
import Tooltip from '@mui/material/Tooltip';
import MainSearchBar from './ui_components/MainsearchBar';

const WAIT_INTERVAL = 1000;

export class Statusside extends Component{
    constructor(props) {
      super(props);
    this.state = {
        search: [],
        searchbar: false,
        searchterm_comp: "",
        squareclicked: false,
        showresult: false,
        industryclicked: false,
        filter: null,
        fss: true,
        fssInfoShow: false,
        placesInfoShow: false,
        clusterInfoShow: false,
        buildingsInfoShow: false,
        trafficInfoShow: false,
        shoppingInfoShow: false,
        cluster: true,
        question: 'none',
        percent_diff: null,
        color_percentdiff: null,
        arrangement: [],
        hidesidebar: false,
        prospect: false,
        prospect_place: false,
        search_comp_values: [],
        prossearch: '',
        back_color: 'white',
        showlayer: 'none',
        showview: 'none',
        searchterm: '',
        mapview: "Default",
        prostype: 'Prospecting Location',
        type: []
    }
    this.search_loc = this.search_loc.bind(this);
    this.prospecttotalhide = this.prospecttotalhide.bind(this);
    this.prospectsetup = this.prospectsetup.bind(this);
    this.prospectsetupcurrent = this.prospectsetupcurrent.bind(this);
    this.prospectsenddata = this.prospectsenddata.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.searchclick = this.searchclick.bind(this);
    }
    componentWillMount(){
        this.timer= null;
    }
    triggersend = async (searchTerm, type) =>  {
        if(searchTerm === '') return;
        let token = localStorage.getItem("auth-token");
        var search_res = await axios.post('/gettheurl/location', {searcher:true, search:searchTerm ,center: this.props.center, bounds:''}, {headers:{ "auth-token": token}});
        let search = await search_res.data;
        
        // if(this.state.prostype === "Prospecting Location"){
        if(type){
            console.log('search: ', search)

            search = search.filter((e) => e.position || e.subcategory).slice(0, 6)
            console.log('searching_values: ', search)
            this.setState({search});    
        }else{
            search = search.filter((e) => !e.position && !e.subcategory)
            search = search.map((e) => e.title)
            function onlyUnique(value, index, self) { return self.indexOf(value) === index;}
            search = search.filter(onlyUnique);

            this.setState({search_comp_values: search})
        }
            
    }
    async search_loc(e) {
        this.setState({searchterm: e})
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.triggersend(e, true), WAIT_INTERVAL);
    }
    async search_boundaries(){
        // if(this.state.prostype === "Search Boundaries"){
            let token = localStorage.getItem("auth-token");
            var search_res = await axios.post('/gettheurl/locationquery', {search: this.state.searchterm ,center: this.props.center, bounds:''}, {headers:{ "auth-token": token}});
            let search = await search_res.data;
            if(search.length> 0 ){
                search.map((ee) => this.props.createmarkerhere(ee, "blue"))
                
                this.hideresults()
            }
        // }
    }
    handleKeyDown(e) { if (e.key === 'Enter') { this.search_boundaries();}}
    searchclick(){
        this.search_boundaries()
    }
    prospectsetup(){
        this.setState({prospect: false,  }, () =>{
            this.props.dropthepin(['Prospect Business', 'prospect'], 'droper')
        })
    }
    showresults = () => {
        this.prospecttotalhide()
        this.setState({showresult: true})
      }
    hideresults(){
        this.setState({showresult: false})
    }
    squareclick_show(){
          this.setState({squareclicked: !this.state.squareclicked})
        
    }
    sendingnew = (pos, title, subcategory, Image) => {
        // console.log('pos: ', pos, title, subcategory)
        /** This sends the data to the parent */
        if(subcategory){
            this.props.searchBarGetBrand({title, subcategory, Image, status: true})
        }else{
            this.props.droppingcheck({type:"Point", coordinates: [pos[1], pos[0]]}, title)
        }
        this.hideresults();
    }
    sendingnew_comp = (title) =>{
        this.setState({searchterm: title, search_comp_values:[]}, () =>{ this.search_boundaries() })

    }
    prospecttotal(){
        
        this.setState({prospect: true});
    }
    prospecttotalhide(){
        this.setState({prospect: false})
    }

    prospectsetupcurrent(){
        this.setState({prospect: false,  }, () =>{
            this.props.dropthepin(['Current Business', 'business'], 'droper')
        })
    }

    _handleKeyDown(e) { if (e.key === 'Enter') this.prospectsenddata() }
    prospectsearch(e){
          this.setState({prossearch: e.target.value})
    }
    async prospectsenddata(){
       let search =  await axios.post('/gettheurl/prospectsearch', { search: this.state.prossearch });
       search = await search.data;
       console.log(search, 'search value')
       if(search.address)
        {   let coordinates = {};
            coordinates.latlng = {lat: search.coordinates.Latitude, lng: search.coordinates.Longitude};
            this.setState({searchbar: false});
            this.props.dropthepin(this.state.type,'search', coordinates,search.address)
        }else{
            this.setState({back_color: 'rgb(250,128,114)'})
        }
    }
    showmaplayers(){
        this.setState({showlayer: 'block'})
    }
    hidemaplayers(){
        this.setState({showlayer: 'none'})
    }
    showview = () => {
        this.setState({showview: 'block'})
    }
    hideview = () => {
        this.setState({showview: 'none'})
    }
    // prospectingtype(){
    //     console.log(this.state.prostype)
    //     if(this.state.prostype == "Prospecting Location"){
    //         this.setState({prostype:"Search Boundaries", search:[]})
    //     }
    //     else{
    //     this.setState({prostype:"Prospecting Location"})
    //     }
    // }
    changemapview(map, val){
        this.setState({mapview: val})
        this.props.maptilelayer(map)
        
    }

    
    showzoomed = (img, turf_area=210000000) => {
        if(this.props.turf_area >= turf_area) 
        {
          if(img) return "Building-imagemain-layer-blue";
          
          return "Building-each2 Building-each-selected-blue"
        }
        if(img) return "Building-imagemain-layer"
        return "Building-each2 Building-each-selected"
    }

    showPlaces = (img, indicator) => {
        if(!this.props.placeSearch) 
        {
          if(img) return "Building-imagemain-layer-blue";
          
          return "Building-each2 Building-each-selected-blue"
        }
        if(img) return "Building-imagemain-layer"
        return "Building-each2 Building-each-selected"
    }

    showInfo = (hovering, type, turf_area = 210000000) => {
        const typeConfig = {
            // fss: { condition: !this.props.placeSearch, stateKey: 'fssInfoShow' },
            cluster: { condition: this.props.cluster && this.props.turf_area >= turf_area, stateKey: 'clusterInfoShow' },
            places: { condition: !this.props.placeSearch, stateKey: 'placesInfoShow' },
            buildings: { condition: this.props.building && this.props.turf_area >= turf_area, stateKey: 'buildingsInfoShow' },
            traffic: { condition: this.props.traffic && this.props.turf_area >= turf_area, stateKey: 'trafficInfoShow' },
            shopping: { condition: this.props.shoppingcenter && this.props.turf_area >= turf_area, stateKey: 'shoppingInfoShow' },
        };
    
        const config = typeConfig[type];
        if (config) {
            this.setState({ [config.stateKey]: hovering && config.condition });
        }
    };    

    getPopUp = (type, title="Zoom in to refresh" ) => {
        const openList = {
            fss: this.state.fssInfoShow,
            places: this.state.placesInfoShow,
            cluster: this.state.clusterInfoShow,
            buildings: this.state.buildingsInfoShow,
            traffic: this.state.trafficInfoShow,
            shopping: this.state.shoppingInfoShow
        }
        return <Tooltip 
            title={title}
            open={openList[type]}  
            disableFocusListener
            disableTouchListener
            placement="right"
            PopperProps={{
            modifiers: [
                {
                name: 'offset',
                options: {
                    offset: [-30, 55],  
                },
                },
            ],
            }}
        >
            <span>{}</span>
        </Tooltip>
    }

    render() {
      
        return (
            <div id="Status-total" >
            {(!this.state.showresult && !this.props.showTour)?<div className="container" onClick={ this.showresults} >
            <div className="search"></div>
            </div>:null}
            { (this.state.showresult || this.props.showTour) &&<OutsideClickHandler onOutsideClick={() => {this.hideresults()}} > <MainSearchBar sendingnew={this.sendingnew} showTour={this.props.showTour} search={this.state.search} search_loc={this.search_loc} searchterm={this.state.searchterm}/></OutsideClickHandler>}
            {/* { (this.state.showresult || this.props.showTour) && <div id="status-search-total"  > <OutsideClickHandler onOutsideClick={() => {this.hideresults()}} >  <input data-tut="reactour__search"  type="text" className="search-results-total" placeholder="Search..." onChange={e => this.search_loc(e.target.value, true) } value={this.state.searchterm} />
            <div className="searchbarbuttons-total">
            <div className="search-bar-line"></div><div className="search-bar-search" ><div className="search"></div></div>
            </div>
            <div className="search-results-values search-results-values-sidebar"> {(this.state.search.length > 0) &&  this.state.search.map((i) => <div className="search-results" key={i.id} onMouseDown={() => this.sendingnew(i.position, i.title, i.vicinity, i.subcategory)}>{i.title}  <div className="search-categories">{i.categoryTitle}</div></div>)}</div></OutsideClickHandler></div>} */}
            <div  className={(this.state.squareclicked || this.props.showTour)? "container-layer-open container-layers":"container-layers" } onClick={this.squareclick_show.bind(this)}  data-tut="reactour__scroll" id="react-status-value" >
              <div  className={(this.state.squareclicked || this.props.showTour)? "container-layers-square-open": "container-layers-square"} >
                  {(!this.state.squareclicked && !this.props.showTour) && <div className="container-layers-dots fa fa-ellipsis-v"></div>}
                  </div>
              <div id="container-layers-settings" className={(this.state.squareclicked || this.props.showTour)?"display-block":"display-none"}>
              <div data-tut="reactour__droppin" className={(this.props.prospect_prospect)?"container-layers-eachval":"container-layers-eachval marker-pin-inside-grey"} onClick={(this.props.prospect_prospect)?this.prospectsetup:() =>{alert("You are out of locations to add. Upgrade your account in the settings")}}><img className="container-layers-image3" src={pin_img}></img><div>Drop Pin</div></div>

              <div className="container-layers-eachval"   onMouseEnter={this.showview} onMouseLeave={this.hideview}> <img className="container-layers-image" src={map_view}></img> Map View
                  <div id= "container-layers-settings-side" style={{display:this.state.showview, top:"25px"}} >
                    
                    <div className={(this.state.mapview === "Default")?"container-clicked container-layers-name": "container-layers-name"} onClick={() => this.changemapview("streets-v11", "Default")}>Default</div>
                    <div className={(this.state.mapview === "Satelite")?"container-clicked container-layers-name": "container-layers-name"} onClick={() => this.changemapview("satellite-streets-v11", "Satelite")}>Satelite</div>
                    <div className={(this.state.mapview === "Light")?"container-clicked container-layers-name": "container-layers-name"} onClick={() => this.changemapview("light-v9", "Light")}>Light</div>
                    <div className={(this.state.mapview === "Dark")?"container-clicked container-layers-name": "container-layers-name"} onClick={() => this.changemapview("dark-v9", "Dark")}>Dark</div>
                    <div className={(this.state.mapview === "Topographic")?"container-clicked container-layers-name": "container-layers-name"} onClick={() => this.changemapview("outdoors-v9", "Topographic")}>Topographic</div>
                  </div>
                  
              </div>
              {/* <div className={(this.props.refreshing_auto_value)? "container-clicked container-layers-eachval":"container-layers-eachval"}  onClick={() => this.props.refreshing_auto()}  > <img className="container-layers-image5" src={refresh}></img>Auto Refresh</div> */}
              <div className={`container-layers-eachval2 ${this.props.building_model && "container-clicked"}`} onClick={() => this.props.turnthison('building_model')} > <img className="container-layers-imagemain" src={building_model}></img><div className= "container-layer-text">3D Model</div></div>
              <div className="container-layers-eachval2" onClick={() => this.props.showTourAgain()} > <img className="container-layers-imagemain" src={tour_map}></img><div className="container-layer-text">Show Tour</div></div>
              {/* <div className="container-layers-eachval2" onClick={() => window.location.replace("/tasks")} > <img className="container-layers-imagemain" src={Tasks}></img><div className="container-layer-text">Task Manager</div></div> */}
              <div className="container-layers-eachval2" onClick={() => window.location.href = "https://mapchise.com/tutorial-page/"} > <img className="container-layers-imagemain" src={TutorialImage}></img><div className="container-layer-text">Tutorial Page</div></div>
              <div className="container-layers-eachval2" onClick={() => window.location.href = "/competitor"} > <img className="container-layers-imagemain" src={CompetitorImage}></img><div className="container-layer-text">Competitor Search</div></div>
              <div className="container-layers-eachval2" onClick={() => window.location.href = "/account"} > <img className="container-layers-imagemain" src={settings_map}></img><div className="container-layer-text">Settings</div></div>
              </div>
            </div> 
            <div id="Layer-filter-total" data-tut='reactour__maplayer'>
                <div className="Layer-filter-name">LAYERS</div>
                <div className="Layer-filter-each" onClick={()=> {this.props.freetier ? this.props.premiumFeatureClicked() : this.props.turnthison('building')}} onMouseOver={() => this.showInfo(true, 'buildings', 20000000000)} onMouseOut={() => this.showInfo(false, 'buildings', 20000000000)}>
                {(this.props.freetier)? <div className="fa fa-lock Layer-filter-lock-freetier" ></div>:
                <div><div className={(this.props.building_show)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                <Buildings  className={(this.props.building)?this.showzoomed(true, 20000000000):"Competitor-sidebar-image-layer"}/></div>}
                     <div className={(this.props.building)?this.showzoomed(false, 20000000000): "Building-each2"}>Buildings</div>
                     {this.getPopUp('buildings')}
                </div>

                {/* <div className="Layer-filter-each" onClick={()=> this.props.turnthison('fss')}  onMouseOver={() => this.showInfo(true, 'fss')} onMouseOut={() => this.showInfo(false, 'fss')}> 
                <div className={(this.props.competitor_show)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                <Competitor  className={(this.props.competitorSearch)?this.showPlaces(true, this.props.competitor_show):"Competitor-sidebar-image-layer"}/>
                <div className={(this.props.competitorSearch)?this.showPlaces(false, this.props.competitor_show): "Building-each2"}>Competitors</div>
                {this.getPopUp('fss')}
                </div> */}
                <div className="Layer-filter-each" onClick={()=> {this.props.freetier ? this.props.premiumFeatureClicked() : this.props.turnthison('cluster')}} onMouseOver={() => this.showInfo(true, 'cluster')} onMouseOut={() => this.showInfo(false, 'cluster')}>
                {(this.props.freetier)? <div className="fa fa-lock Layer-filter-lock-freetier" ></div>:<div>
                <Cluster  className={(this.props.cluster)?this.showzoomed(true):"Competitor-sidebar-image-layer"}/>
                </div>}
                     <div className={(this.props.cluster)?this.showzoomed(false): "Building-each2"}>Cluster</div>
                     {this.getPopUp('cluster')}
                </div>
                <div className="Layer-filter-each" onClick={()=> this.props.turnthison('datalayer')}>
                <div className={(this.props.datalayer_show)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                <Datalayer  className={(this.props.datalayer)?"Building-imagemain-layer":"Competitor-sidebar-image-layer"}/>
                     <div className={(this.props.datalayer)?"Building-each2 Building-each-selected": "Building-each2"}>Data Layer</div>
                </div>
                <div className="Layer-filter-each" onClick={()=> {this.props.freetier ? this.props.premiumFeatureClicked() :this.props.turnthison('places')}} onMouseOver={() => this.showInfo(true, 'places')} onMouseOut={() => this.showInfo(false, 'places')}>
                {(this.props.freetier)? <div className="fa fa-lock Layer-filter-lock-freetier" ></div>:<div>
                <div className={(this.props.places_show)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                <Places  className={(this.props.places)? this.showPlaces(true, this.props.placeSearch):"Competitor-sidebar-image-layer"}/></div>}
                     <div className={(this.props.places)? this.showPlaces(false, this.props.placeSearch): "Building-each2"}>Places</div>
                     {this.getPopUp('places')}
                </div>
                <div className="Layer-filter-each" onClick={()=> {this.props.freetier ? this.props.premiumFeatureClicked() :this.props.turnthison('shoppingcenter')}} onMouseOver={() => this.showInfo(true, 'shopping')} onMouseOut={() => this.showInfo(false, 'shopping')}> 
                {(this.props.freetier)? <div className="fa fa-lock Layer-filter-lock-freetier" ></div>:
                <Shop  className={(this.props.shoppingcenter)?this.showzoomed(true, 20000000000):"Competitor-sidebar-image-layer"}/>}
                <div className={(this.props.shoppingcenter)?this.showzoomed(false, 20000000000): "Building-each2"}>Shopping Centers</div>
                {this.getPopUp('shopping')}
                </div>
                
                <div className="Layer-filter-each" onClick={()=> this.props.freetier ? this.props.premiumFeatureClicked() : this.props.turnthison('traffic')} id="layer-traffic-total" onMouseOver={() => this.showInfo(true, 'traffic', 20000000000)} onMouseOut={() => this.showInfo(false, 'traffic', 20000000000)}> 
                {(this.props.freetier)? <div className="fa fa-lock Layer-filter-lock-freetier" ></div>:
                <Traffic  className={(this.props.traffic)?this.showzoomed(true, 20000000000):"Competitor-sidebar-image-layer"}/>}
                <div className={(this.props.traffic)?this.showzoomed(false, 20000000000): "Building-each2"}>Traffic Layer</div>
                {this.getPopUp('traffic')}
                </div>
                <div className="Layer-filter-each" onClick={()=> this.props.turnthison('other_layer_tabulation')}> 
                    <div className={(this.props.otherLayerShow)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                    <Zipcode  className={(this.props.otherLayersList.length)?"Building-imagemain-layer":"Competitor-sidebar-image-layer"}/>
                    <div className={(this.props.otherLayersList.length)?"Building-each2 Building-each-selected": "Building-each2"}>Other Layers</div>
                </div>
            </div>

            {/* {(this.state.searchbar)?<div className="prospect-search-total"><div className="prospect-background"><OutsideClickHandler       onOutsideClick={() => {this.prospecttotalsearchhide()}}><div className="prospect-searchbar-total">
                <input className="prospect-searchbar" onKeyDown={this._handleKeyDown} onChange={this.prospectsearch.bind(this)} style={{'background-color': this.state.back_color}}></input><button className="prospect-searchbotton" onClick={this.prospectsenddata}>Search</button></div></OutsideClickHandler></div></div>:null} */}
            </div>
        )}
}