import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: -10px;
  bottom: 5px;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: bold;
  width: 48px;
  color: ${props => props.$isActive ? '#111827' : '#6B7280'};
  text-align: ${props => props.$align};
`;

const SwitchButton = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 25px;
  width: 40px;
  margin: 0 8px;
  border-radius: 9999px;
  transition: background-color 0.3s;
  background-color: ${props => props.$isOpen ? '#40ad25' : 'red'};
  cursor: pointer;
  
  &:focus {
    outline: none;
    ring: 2px solid #40ad25;
    ring-offset: 2px;
  }
`;

const Thumb = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(${props => props.$isOpen ? '17px' : '2px'});
  transition: transform 0.3s;

  svg {
    width: 14px;
    height: 14px;
    color: ${props => props.$isOpen ? '#40ad25' : 'red'};
  }
`;

const CustomSwitch = ({ places_change_closed }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    places_change_closed(newState)
    
  };

  return (
    <SwitchContainer>
      <Label $isActive={!isOpen} $align="right">
        Closed
      </Label>
      
      <SwitchButton
        onClick={handleToggle}
        $isOpen={isOpen}
      >
        <span className="sr-only">{isOpen ? 'Open' : 'Closed'}</span>
        <Thumb $isOpen={isOpen}>
          {isOpen ? <CheckIcon /> : <CloseIcon />}
        </Thumb>
      </SwitchButton>

      <Label $isActive={isOpen} $align="left">
        Open
      </Label>
    </SwitchContainer>
  );
};

export default CustomSwitch;